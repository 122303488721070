var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',{ref:"dynamicValidateForm",attrs:{"layout":"inline","model":_vm.dynamicValidateForm}},[_c('a-table',{staticClass:"batch-table",attrs:{"pagination":false,"rowKey":record => record.key,"columns":_vm.columns,"dataSource":_vm.dynamicValidateForm.domains,"bordered":"","scroll":{
      x: _vm.listLength * 190 + 80 + (!_vm.record.options.hideSequence ? 60 : 0),
      y: _vm.record.options.scrollY
    }},scopedSlots:_vm._u([_vm._l((_vm.record.list),function(item){return {key:item.key,fn:function(text, row, index){return [_c('KFormModelItem',{key:item.key + '1',attrs:{"record":item,"config":_vm.config,"parentDisabled":_vm.disabled,"index":index,"domains":_vm.dynamicValidateForm.domains,"dynamicData":_vm.dynamicData},on:{"input":_vm.handleInput},model:{value:(row[item.model]),callback:function ($$v) {_vm.$set(row, item.model, $$v)},expression:"row[item.model]"}})]}}}),{key:"dynamic-opr-button",fn:function(text, row){return [_c('div',{staticStyle:{"witdh":"130px"}},[(!_vm.disabled)?_c('a-icon',{staticClass:"dynamic-opr-button",attrs:{"title":"复制添加","type":"copy-o"},on:{"click":function($event){return _vm.copyDomain(row)}}}):_vm._e(),(
            !_vm.disabled &&
              _vm.record.options.minLimit < _vm.dynamicValidateForm.domains.length
          )?_c('a-icon',{staticClass:"dynamic-opr-button",attrs:{"title":"删除该行","type":"minus-circle-o"},on:{"click":function($event){return _vm.removeDomain(row)}}}):_vm._e()],1)]}}],null,true)}),_c('Button',{attrs:{"type":"dashed","disabled":_vm.disabled},on:{"click":_vm.addDomain}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("增加 ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }